
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import {
  checkRolePermission,
  convertDecimalWithComma,
  dateToDateString,
  dateToTime,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { CargoData, CargoDetailStt } from "@/domain/entities/Cargo";
import {
  CARGO,
  INCOMING_OUTGOING
} from "@/app/infrastructures/misc/RolePermission";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import Print from "../modules/print.vue";
import {
  ModalMessageEntities,
  QueryParamsEntities2
} from "@/domain/entities/MainApp";
import router from "@/app/ui/router";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import formatValueNumber from "@/app/infrastructures/misc/common-library/FormatValueNumber";
import { FlagsPermissionCargo } from "@/feature-flags/flags-permission-cargo";
import moment from "moment";


@Options({
  components: {
    DetailLayout,
    Title,
    Print
  }
})
export default class Detail extends Vue {
  confirmationCancelCargo() {
    CargoController.setCancelFromDetail(true);
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: this.$t("Apakah anda yakin ingin membatalkan?"),
        message: this.$t(
          'Jika memilih "Ya" sistem akan membatalkan proses booking'
        ),
        textCancel: this.$t("Ya"),
        textSuccess: this.$t("Tidak"),
        onSubmit: () => MainAppController.closeMessageModal(),
        onClose: () => this.cancelCargo(),
        image: "badge-confirmation-general"
      })
    );
  }

  confirmationDeleteCargo() {
    CargoController.setDeleteFromDetail(true);
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: this.$t("Apakah anda yakin ingin menghapus?"),
        message: this.$t(
          "Data yang sudah dihapus akan hilang dari daftar Dibatalkan/Gagal"
        ),
        textCancel: this.$t("Ya"),
        textSuccess: this.$t("Tidak"),
        onSubmit: () => MainAppController.closeMessageModal(),
        onClose: () => this.deleteCargo(),
        image: "badge-confirmation-general"
      })
    );
  }

  get partnerName() {
    return this.detailData.partnerName || "-";
  }

  get partnerType() {
    return this.detailData.partnerType || "-";
  }

  get originCity() {
    return this.detailData.originCityCode && this.detailData.originCityName
      ? `${this.detailData.originCityCode} - ${this.detailData.originCityName}`
      : "-";
  }

  get destinationCity() {
    return this.detailData.destinationCityCode &&
      this.detailData.destinationCityName
      ? `${this.detailData.destinationCityCode} - ${this.detailData.destinationCityName}`
      : "-";
  }

  get cargoType() {
    return this.detailData.cargoType || "-";
  }

  get destinationAirport() {
    if (
      !this.detailData.airportDestinationCityCode ||
      !this.detailData.airportDestinationCityName
    ) {
      return "-";
    }
    return this.detailData.cargoType === "plane"
      ? `${this.detailData.airportDestinationCityCode} - ${this.detailData.airportDestinationCityName}`
      : "-";
  }

  get cargoCommodity() {
    return this.detailData.cargoCommodity || "-";
  }

  get cargoProduct() {
    return this.detailData.cargoProduct || "-";
  }

  get nog() {
    return this.detailData.nog || "-";
  }

  get cargoNumberOrBookingRequestId() {
    return this.detailData.bookingRequestId || this.detailData.cargoNumber;
  }

  get vehiclePoliceNumber() {
    return this.detailData.vehiclePoliceNumber || "-";
  }

  get departureDate() {
    return formatDate(this.detailData.departureDate);
  }

  get arrivalDate() {
    return formatDate(this.detailData.arrivalDate);
  }

  get totalStt() {
    return this.detailData.totalStt ? this.detailData.totalStt.toString() : "-";
  }

  get totalPieces() {
    return this.detailData.totalPieces
      ? this.detailData.totalPieces.toString()
      : "-";
  }

  get totalEstCargoGrossWeight() {
    return this.detailData.totalEstCargoGrossWeight
      ? `${this.convertDecimalWithComma(
          this.detailData.totalEstCargoGrossWeight
        )} Kg`
      : "-";
  }

  get totalEstCargoVolumeWeight() {
    return this.detailData.totalEstCargoVolumeWeight
      ? `${this.convertDecimalWithComma(
          this.detailData.totalEstCargoVolumeWeight
        )} Kg`
      : "-";
  }

  get totalGrossWeight() {
    return this.detailData.totalGrossWeight
      ? `${this.convertDecimalWithComma(this.detailData.totalGrossWeight)} Kg`
      : "-";
  }

  get totalVolumeWeight() {
    return this.detailData.totalVolumeWeight
      ? `${this.convertDecimalWithComma(this.detailData.totalVolumeWeight)} Kg`
      : "-";
  }

  get totalActualCargoGrossWeight() {
    return this.detailData.totalActualCargoGrossWeight
      ? `${this.formatValueNumber(
          this.detailData.totalActualCargoGrossWeight
        )} Kg`
      : "-";
  }

  get totalActualCargoVolumeWeight() {
    return this.detailData.totalActualCargoVolumeWeight
      ? `${this.formatValueNumber(
          this.detailData.totalActualCargoVolumeWeight
        )} Kg`
      : "-";
  }

  get cargoActualPiece() {
    return this.detailData.cargoActualPiece
      ? this.detailData.cargoActualPiece.toString()
      : "-";
  }

  get configTimeoutCancelCargo() {
    return FlagsPermissionCargo.config_time_cancel_cargo.getValue();
  }

  get isButtonAble() {
    return FlagsPermissionCargo.feature_flight_v2_enable.isEnabled();
  }

  dateTimeNow = moment();

  get diffMinutesDateTime() {
    if (this.configTimeoutCancelCargo === 0) return true;
    const created = this.$moment(this.detailData.createdAt);
    const now = this.dateTimeNow;
    const diff = now.diff(created, "minutes");
    return diff >= this.configTimeoutCancelCargo;
  }

  get isAbleToCancel() {
    return this.detailData.cargoStatus.includes("process") && this.diffMinutesDateTime
  }

  get createdAt() {
    return formatDate(this.detailData.createdAt);
  }

  get createdBy() {
    return this.detailData.createdBy || "-";
  }

  get shcValue() {
    return (
      `${this.detailData.shcCode} - ${this.detailData.shcDescription}` || "-"
    );
  }

  get ableToEdit() {
    return (
      checkRolePermission(CARGO.EDIT) ||
      checkRolePermission(INCOMING_OUTGOING.CARGO_EDIT)
    );
  }
  mounted() {
    this.fetchDetailData();
    this.refs = this.$refs;
    this.startIntervalDateTime();
  }

  unmounted() {
    CargoController.resetCargoData();
    this.stopInterval();
  }

  dateTimeInterval = null as any;

  startIntervalDateTime() {
    this.dateTimeInterval = setInterval(() => {
      this.dateTimeNow = moment();
    }, 1000);
  }

  stopInterval() {
    clearInterval(this.dateTimeInterval);
  }

  get cargoNo(): any {
    return decodeURIComponent(String(this.$route.params?.id));
  }
  get rcNo(): any {
    return decodeURIComponent(String(this.$route.query?.rc_no));
  }

  get isFromListFailedBooking() {
    return this.$route.query.rc_no;
  }

  fetchDetailData() {
    const isPrint = false;
    if (this.$route.query.rc_no) {
      CargoController.getDetailRetryCargo({
        cargoNo: this.rcNo,
        isPrint: isPrint
      });
    } else {
      CargoController.getDetailCargo({
        cargoNo: this.cargoNo,
        isPrint: isPrint
      });
    }
  }

  get detailData(): CargoData {
    return CargoController.cargoDetailData;
  }

  get accountData() {
    return AccountController.accountData;
  }

  get isLoading() {
    return CargoController.isLoading;
  }

  get isError() {
    return CargoController.isError;
  }
  get errorCause() {
    return CargoController.errorCause;
  }

  get totalDimension() {
    if (
      !this.detailData.actualDimension.length ||
      !this.detailData.actualDimension.width ||
      !this.detailData.actualDimension.height
    ) {
      return "-";
    }
    return `
      ${this.detailData.actualDimension.length} x 
      ${this.detailData.actualDimension.width} x 
      ${this.detailData.actualDimension.height} CM
    `;
  }

  get isSttEmpty() {
    return !this.detailData.detailStt.length;
  }

  showModalReBooking = false;
  handlerModalReBooking(val: boolean) {
    this.showModalReBooking = val;
  }

  goToReBooking() {
    const bookingQueryObject = {
      autofill: true,
      rcId: this.$route.query.rc_no
    };
    const bookingQuery = new QueryParamsEntities2(bookingQueryObject);
    router.push(`/cargo/create?${bookingQuery.queryString}`);
    ReadyToCargoController.setFlowBooking("rebooking");
    CargoController.setIsDeleteStt(false);
    this.handlerModalReBooking(false);
  }

  handlerCancelCargo(id: number, cargoNo: string) {
    CargoController.setCargoIdToCancelOrDelete(id);
    CargoController.setCargoNoForModal(cargoNo);
  }

  handlerDeleteCargo(id: number, cargoNo: string) {
    CargoController.setCargoIdToCancelOrDelete(id);
    CargoController.setCargoNoForModal(cargoNo);
  }

  // table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-nowrap",
        render: (_: CargoDetailStt, i: number) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${i +
            1}</span>`
      },
      {
        name: "No. Bag",
        styleHead: "w-72 text-left whitespace-nowrap",
        render: (item: CargoDetailStt) =>
          `<div class="font-normal text-black-lp-300 whitespace-nowrap">${item.baggingVendorNumber ||
            item.baggingNumber ||
            "-"}</div>`
      },
      {
        name: "No. STT",
        styleHead: "w-56 text-left whitespace-nowrap",
        render: (item: CargoDetailStt) => {
          return `<div class="text-black-lp-300 whitespace-nowrap">
                  ${item.sttNumber || "-"}
                </div>`;
        }
      },
      {
        name: this.$t("Total Koli"),
        styleHead: "w-40 text-left whitespace-nowrap",
        styleCustom: "justify-center",
        render: (item: CargoDetailStt) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${this.totalPiecesColumnData(
            item.totalPieces
          )}</span>`
      },
      {
        name: this.$t("Berat Kotor"),
        styleHead: "w-64 text-left whitespace-nowrap",
        render: (item: CargoDetailStt) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${this.decimalNumberColumnData(
            item.grossWeigth
          )}</span>`
      },
      {
        name: this.$t("Berat Dimensi"),
        styleHead: "w-64 text-left whitespace-nowrap",
        render: (item: CargoDetailStt) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${this.decimalNumberColumnData(
            item.volumeWeight
          )}</span>`
      },
      {
        name: this.$t("Produk"),
        styleHead: "w-56 text-left whitespace-nowrap",
        render: (item: CargoDetailStt) => {
          if (!item.productType) {
            return `<span class="font-normal text-black-lp-300 whitespace-nowrap">-</span>`;
          }
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.productType}
                  </div>
                </div>`;
        }
      },
      {
        name: this.$t("Komoditas"),
        styleHead: "w-72 text-left whitespace-nowrap",
        render: (item: CargoDetailStt) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${item.commodity ||
            "-"}</span>`
      },
      {
        name: "Origin",
        styleHead: "w-56 text-left whitespace-nowrap",
        render: (item: CargoDetailStt) => {
          if (!item.origin) {
            return `<span class="font-normal text-black-lp-300 whitespace-nowrap">-</span>`;
          }
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.origin}
                  </div>
                </div>`;
        }
      },
      {
        name: this.$t("Dest."),
        styleHead: "w-56 text-left whitespace-nowrap",
        render: (item: CargoDetailStt) => {
          if (!item.destination) {
            return `<span class="font-normal text-black-lp-300 whitespace-nowrap">-</span>`;
          }
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.destination}
                  </div>
                </div>`;
        }
      }
    ];
  }

  pagination = {
    page: 1,
    limit: 100
  };

  // route navigatiion
  goBack() {
    const path =
      (this.$route.meta as any)["parent-path"] || this.$route.matched[0].path;
    this.$router.push(path);
  }
  goToEdit() {
    this.$router.push(`${this.$route.path}/edit`);
  }

  convertDecimalWithComma(value: any) {
    return convertDecimalWithComma(value, 2);
  }

  decimalNumberColumnData(number: number | string) {
    const decimalNumber = convertDecimalWithComma(number, 2);
    return decimalNumber !== 0 ? `${decimalNumber} Kg` : "-";
  }

  totalPiecesColumnData(total: number) {
    return total != 0 ? total : "-";
  }

  formatValueNumber(number: any) {
    if (typeof number === "string") {
      const convert = +number.replace(",", ".");
      return formatValueNumber(convert);
    }
    return formatValueNumber(number);
  }

  // Print
  check = null;
  refs: any = "";
  cargoNumber = "";

  async printPdf() {
    this.refs.print.printManifestV2(this.cargoNo);
    this.cargoNumber = this.cargoNo;
  }

  async printLabel(cargoNo: string, type: string) {
    this.refs.print.printData(cargoNo, "label", type);
    this.cargoNumber = this.cargoNo;
  }

  cancelCargo() {
    CargoController.cancelCargo();
    this.handlerModalCancelBooking(false);
  }
  deleteCargo() {
    CargoController.deleteCargo();
    this.handlerModalDeleteBooking(false);
  }
  showModalCancelBooking = false;
  handlerModalCancelBooking(val: boolean) {
    this.showModalCancelBooking = val;
  }
  showModalDeleteBooking = false;
  handlerModalDeleteBooking(val: boolean) {
    this.showModalDeleteBooking = val;
  }
}
